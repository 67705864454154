// import { useState, useEffect, useRef, useCallback } from "react";
// import "./quickstart.css";

// const Quickstart = () => {
//   const [responseType, setResponseType] = useState("text");
//   const [selectedVoice, setSelectedVoice] = useState("alloy");
//   const [messages, setMessages] = useState([]);
//   const [query, setQuery] = useState("");

//   const chatBoxRef = useRef(null);
//   const websocketRef = useRef(null);

//   const playAudio = (base64Audio) => {
//     const binaryData = Uint8Array.from(atob(base64Audio), (c) => c.charCodeAt(0));
//     const audioBlob = new Blob([binaryData], { type: "audio/wav" });
//     const audioUrl = URL.createObjectURL(audioBlob);
//     const audio = new Audio(audioUrl);
//     audio.play();
//   };

//   // const handleWebSocketMessage = useCallback((event) => {

//   //   const data = JSON.parse(event.data);

//   //   if (data.content) {
//   //     setMessages((prev) => {
//   //       const lastMessage = prev.length > 0 ? prev[prev.length - 1] : null;

//   //       if (lastMessage && lastMessage.sender === "AI") {
//   //         return [...prev.slice(0, -1), { sender: "AI", content: lastMessage.content + " " + data.content }];
//   //       } else {
//   //         return [...prev, { sender: "AI", content: data.content }];
//   //       }
//   //     });
//   //   }

//   //   if (data.audio_data && responseType === "audio") {
//   //     playAudio(data.audio_data);
//   //   }
//   // }, [responseType]);

//   const handleWebSocketMessage = useCallback((event) => {
//     const data = JSON.parse(event.data);

//     if (data.content) {
//       // Display both text and audio when in audio mode
//       setMessages((prev) => {
//         const lastMessage = prev.length > 0 ? prev[prev.length - 1] : null;

//         if (lastMessage && lastMessage.sender === "AI") {
//           return [
//             ...prev.slice(0, -1),
//             { sender: "AI", content: lastMessage.content + " " + data.content },
//           ];
//         } else {
//           return [...prev, { sender: "AI", content: data.content }];
//         }
//       });
//     }

//     if (data.audio_data && responseType === "audio") {
//       playAudio(data.audio_data);  // Play the audio
//       setMessages((prev) => [
//         ...prev,
//         { sender: "AI", content: "(Audio Response)" }, // Optionally add a placeholder for audio response in text form
//       ]);
//     }
//   }, [responseType]);

//   const connectWebSocket = useCallback(() => {
//     const websocket = new WebSocket("wss://devapi.ivoz.ai/llm-campaigns/ws/demo/");
//     websocketRef.current = websocket;

//     websocket.onopen = () => {
//       websocket.send(
//         JSON.stringify({ type: "initial_config", voice: selectedVoice, response_type: responseType })
//       );
//     };

//     websocket.onmessage = handleWebSocketMessage;
//     websocket.onclose = () => setTimeout(connectWebSocket, 3000);
//   }, [selectedVoice, responseType, handleWebSocketMessage]);

//   useEffect(() => {
//     connectWebSocket();
//     return () => {
//       if (websocketRef.current) websocketRef.current.close();
//     };
//   }, [connectWebSocket]);

//   const handleSendMessage = () => {
//     if (!query.trim()) return;
//     websocketRef.current.send(
//       JSON.stringify({ query, response_type: responseType, voice: selectedVoice })
//     );
//     setMessages((prev) => [...prev, { sender: "You", content: query }]);
//     setQuery("");
//   };

//   const handleVoiceToggle = () => {
//     setResponseType((prev) => (prev === "text" ? "audio" : "text"));
//   };

//   const handleVoiceChange = (event) => {
//     setSelectedVoice(event.target.value);
//   };

//   return (
//     <div className="containers">
//       <h1 className="title">Chat With Realtime WebSocket</h1>

//       <div className="controls">
//       <label className="toggle-container">
//   <input type="checkbox" onChange={handleVoiceToggle} checked={responseType === "audio"} />
//   <span className="toggle-slider"></span>
//   <span className="toggle-label">
//     {responseType === "text" ? "Text Mode" : "Audio Mode"}
//   </span>
// </label>

//         <select onChange={handleVoiceChange} value={selectedVoice}>
//           <option value="alloy">Alloy (F)</option>
//           <option value="ash">Ash (M)</option>
//           <option value="ballad">Ballad (M)</option>
//           <option value="coral">Coral (F)</option>
//           <option value="echo">Echo (M)</option>
//           <option value="sage">Sage (F)</option>
//           <option value="shimmer">Shimmer (F)</option>
//           <option value="verse">Verse (M)</option>
//         </select>
//       </div>

//       <div className="chat-box" ref={chatBoxRef}>
//         {messages.map((msg, index) => (
//           <div key={index} className={msg.sender === "You" ? "message user" : "message ai"}>
//             <span className="sender">{msg.sender}: </span>
//             <span>{msg.content}</span>
//           </div>
//         ))}
//       </div>

//       <div className="input-container">
//         <input
//           type="text"
//           value={query}
//           onChange={(e) => setQuery(e.target.value)}
//           onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
//           placeholder="Type your message..."
//         />
//         <button onClick={handleSendMessage}>Send</button>
//       </div>
//     </div>
//   );
// };

// export default Quickstart;

// import React from 'react'
// import './Quickstart.css'
// import QuickstartImage from "../../Image/Quickstart.jpeg"
// import Button from '../Buttons/Button';

// function Quickstart(props) {

//     const Quickstartstyle = {
//         backgroundImage: `url(${QuickstartImage})`,
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//         backgroundRepeat: 'no-repeat',
//       };

//   return (
//     <>
//     <div className="Quickstart-container" style={Quickstartstyle}>
//     <div className="page-center">
//     <div className="Quickstart-container-inner">
//       <div className="Quickstart-content">
//         <h1 class="text_white">{props.raman.title}</h1>
//         <p class="text_white">{props.raman.content}</p>
//         <Button btn={props.buttonText} />
//       </div>
//     </div>
//     </div>
//     </div>
//     </>
//   )
// }

// export default Quickstart




import React, { Component } from "react";
import "./quickstart.css";

class Quickstart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedVoice: "alloy",
      responseType: "text",
      isRecording: false,
      isVoiceChange: false,
      currentAIMessage: null,
    };

    this.textInput = React.createRef();
    this.chatBox = React.createRef();
    this.voiceSelector = React.createRef();
    this.micBtn = React.createRef();
    this.sendBtn = React.createRef();
    this.responseTypeToggle = React.createRef();

    this.websocket = null;
    this.audioContext = null;
    this.isAudioPlaying = false;
    this.audioQueue = [];
    this.mediaRecorder = null;
    this.mediaStream = null;
    this.audioChunks = [];
  }

  componentDidMount() {
    this.initializeAudioContext();

    this.connectWebSocket();

    this.micBtn.current.addEventListener("click", this.handleMicButtonClick);
    this.sendBtn.current.addEventListener("click", this.handleSendButtonClick);
    this.responseTypeToggle.current.addEventListener(
      "change",
      this.handleResponseTypeToggle
    );
    this.voiceSelector.current.addEventListener(
      "change",
      this.handleVoiceChange
    );
    this.textInput.current.addEventListener("keydown", this.handleEnterKey);
  }

  componentWillUnmount() {
    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();
    }
    this.micBtn.current.removeEventListener("click", this.handleMicButtonClick);
    this.sendBtn.current.removeEventListener(
      "click",
      this.handleSendButtonClick
    );
    this.responseTypeToggle.current.removeEventListener(
      "change",
      this.handleResponseTypeToggle
    );
    this.voiceSelector.current.removeEventListener(
      "change",
      this.handleVoiceChange
    );
    this.textInput.current.removeEventListener("keydown", this.handleEnterKey);
  }

  initializeAudioContext() {
    try {
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)({
        sampleRate: 24000,
      });
      console.log(
        `AudioContext initialized with sample rate: ${this.audioContext.sampleRate}`
      );
    } catch (e) {
      console.error(
        "Failed to initialize AudioContext with 24kHz sample rate:",
        e
      );
      alert(
        "Unable to set AudioContext to 24kHz. Audio playback may be incorrect."
      );
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      console.log(
        `AudioContext initialized with default sample rate: ${this.audioContext.sampleRate}`
      );
    }
  }

  sendMessage(message) {
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.send(message);
    } else {
      console.log("WebSocket is still connecting. Retrying in 1 second...");
      setTimeout(() => this.sendMessage(message), 1000);
    }
  }

  connectWebSocket() {
    this.websocket = new WebSocket(
      "wss://devapi.ivoz.ai/llm-campaigns/ws/realtime-chat/"
    );

    this.websocket.onopen = () => {
      console.log("WebSocket connected");
      this.sendMessage(
        JSON.stringify({
          type: "initial_config",
          voice: this.state.selectedVoice,
        })
      );
    };

    this.websocket.onmessage = this.handleWebSocketMessage;
    this.websocket.onclose = this.handleWebSocketClose;
    this.websocket.onerror = (error) =>
      console.error("WebSocket error:", error);
  }

  handleWebSocketMessage = (event) => {
    const data = JSON.parse(event.data);
    if (data.audio_data) {
      this.playPCM16Audio(data.audio_data);
    }
    if (data.content) {
      this.appendAIMessage(data.content);
    }
  };

  handleWebSocketClose = () => {
    if (!this.state.isVoiceChange) {
      console.log("WebSocket closed. Reconnecting in 3 seconds...");
      setTimeout(this.connectWebSocket, 3000);
    } else {
      console.log("WebSocket closed due to voice change, skipping reconnect.");
    }
  };

  handleMicButtonClick = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Audio recording is not supported on your browser.");
      return;
    }
    if (!this.state.isRecording) {
      try {
        this.mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.mediaRecorder = new MediaRecorder(this.mediaStream);
        this.audioChunks = [];
        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        };
        this.mediaRecorder.onstop = async () => {
          const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
          await this.sendAudio(audioBlob);
          this.audioChunks = [];
        };
        this.mediaRecorder.start();
        this.setState({ isRecording: true });

        this.micBtn.current.textContent = "⏹️";
        this.micBtn.current.classList.remove("bg-blue-500");
        this.micBtn.current.classList.add("bg-red-500");
      } catch (error) {
        console.error("Error accessing the microphone:", error);
        alert(
          "Error accessing the microphone. Please check your device permissions."
        );
      }
    } else {
      this.mediaRecorder.stop();
      this.mediaStream.getTracks().forEach((track) => track.stop());
      this.setState({ isRecording: false });

      this.micBtn.current.textContent = "🎤";
      this.micBtn.current.classList.remove("bg-red-500");
      this.micBtn.current.classList.add("bg-blue-500");
    }
  }; 

  handleSendButtonClick = () => {
    const query = this.textInput.current.value.trim();
    if (query) {
      this.appendUserTextMessage(query);
      this.websocket.send(
        JSON.stringify({
          query,
          response_type: this.state.responseType,
          voice: this.state.selectedVoice,
        })
      );
      this.textInput.current.value = "";
      this.setState({ currentAIMessage: null });
    }
  };

  handleResponseTypeToggle = () => {
    const responseType = this.responseTypeToggle.current.checked
      ? "audio"
      : "text";
    this.setState({
      responseType,
    });
  };

  handleVoiceChange = () => {
    const newVoice = this.voiceSelector.current.value;
    if (newVoice !== this.state.selectedVoice) {
      console.log(`Voice changed to: ${newVoice}`);
      this.setState({ selectedVoice: newVoice, isVoiceChange: true });

      if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
        this.websocket.close();
      }
      this.connectWebSocket();
    }
  };

  handleEnterKey = (event) => {
    if (event.key === "Enter") {
      this.sendBtn.current.click();
      event.preventDefault();
    }
  };

  appendUserTextMessage(content) {
    const userMessage = document.createElement("div");
    userMessage.className = "user-message mb-4 text-right";
    userMessage.innerHTML = `
      <span class="font-semibold text-green-600 USER_message">You:</span>
      <span class="text-gray-700">${content}</span>
    `;
    this.chatBox.current.appendChild(userMessage);
    this.chatBox.current.scrollTop = this.chatBox.current.scrollHeight;
  }

  // appendAIMessage(content) {
  //   if (!this.state.currentAIMessage) {
  //     // Create a new div and set state with it using setState
  //     const newAIMessage = document.createElement("div");
  //     newAIMessage.className = "ai-message mb-4";
  //     newAIMessage.innerHTML = `
  //       <span class="font-semibold text-blue-600 AI_message">AI:</span>
  //       <span class="text-gray-700">${content}</span>
  //     `;

  //     // Use setState to update state with the new message element
  //     this.setState({ currentAIMessage: newAIMessage }, () => {
  //       this.chatBox.current.appendChild(this.state.currentAIMessage);
  //       this.chatBox.current.scrollTop = this.chatBox.current.scrollHeight;
  //     });
  //   } else {
  //     const aiContentSpan =
  //       this.state.currentAIMessage.querySelector("span.text-gray-700");
  //     aiContentSpan.innerHTML += content;
  //   }
  // }

  appendAIMessage(content) {
    // Check if there's already an AI message being displayed
    let existingAIMessage = this.chatBox.current.querySelector(".ai-message:last-child");
  
    if (!existingAIMessage || !existingAIMessage.dataset.active) {
      // Create new AI message container
      const newAIMessage = document.createElement("div");
      newAIMessage.className = "ai-message mb-4";
      newAIMessage.dataset.active = "true"; // Mark as active
      newAIMessage.innerHTML = `
        <span class="font-semibold text-blue-600 AI_message">AI:</span>
        <span class="text-gray-700">${content}</span>
      `;
  
      // Append new message to chat box
      this.chatBox.current.appendChild(newAIMessage);
      this.chatBox.current.scrollTop = this.chatBox.current.scrollHeight;
    } else {
      // Update the existing message
      const aiContentSpan = existingAIMessage.querySelector("span.text-gray-700");
      aiContentSpan.textContent += content;
    }
  }
  

  async playPCM16Audio(base64PCM) {
    try {
      const binaryData = atob(base64PCM);
      const pcm16Array = new Int16Array(binaryData.length / 2);
      for (let i = 0; i < pcm16Array.length; i++) {
        pcm16Array[i] =
          binaryData.charCodeAt(i * 2) |
          (binaryData.charCodeAt(i * 2 + 1) << 8);
      }

      const wavBuffer = this.encodeWAV(pcm16Array, 24000);

      const audioBuffer = await this.audioContext.decodeAudioData(wavBuffer);
      this.enqueueAudio(audioBuffer);
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  }

  encodeWAV(pcmData, sampleRate) {
    const numChannels = 1; // mono
    const bitDepth = 16;
    const buffer = new ArrayBuffer(44 + pcmData.length * 2);
    const view = new DataView(buffer);
    /* RIFF identifier */
    this.writeString(view, 0, "RIFF");
    /* file length */
    view.setUint32(4, 36 + pcmData.length * 2, true);
    /* RIFF type */
    this.writeString(view, 8, "WAVE");
    /* format chunk identifier */
    this.writeString(view, 12, "fmt ");
    /* format chunk length */
    view.setUint32(16, 16, true);
    /* sample format (1 = PCM) */
    view.setUint16(20, 1, true);
    /* number of channels */
    view.setUint16(22, numChannels, true);
    /* sample rate */
    view.setUint32(24, sampleRate, true);
    /* byte rate (sample rate * block align) */
    view.setUint32(28, sampleRate * numChannels * (bitDepth / 8), true);
    /* block align (num channels * bytes per sample) */
    view.setUint16(32, numChannels * (bitDepth / 8), true);
    /* bits per sample */
    view.setUint16(34, bitDepth, true);
    /* data chunk identifier */
    this.writeString(view, 36, "data");
    /* data chunk length */
    view.setUint32(40, pcmData.length * 2, true);
    /* PCM data */
    for (let i = 0; i < pcmData.length; i++) {
      view.setInt16(44 + i * 2, pcmData[i], true);
    }
    return buffer;
  }

  // Helper Function to Write String to DataView
  writeString(view, offset, str) {
    for (let i = 0; i < str.length; i++) {
      view.setUint8(offset + i, str.charCodeAt(i));
    }
  }

  // Enqueue Audio for Playback
  enqueueAudio(audioBuffer) {
    if (!this.isAudioPlaying) {
      this.playAudio(audioBuffer);
    } else {
      this.audioQueue.push(audioBuffer);
    }
  }

  // Play Audio from Queue
  playAudio(audioBuffer) {
    if (this.isAudioPlaying) {
      this.audioQueue.push(audioBuffer);
      return;
    }

    this.isAudioPlaying = true;
    const sourceNode = this.audioContext.createBufferSource();
    sourceNode.buffer = audioBuffer;

    const gainNode = this.audioContext.createGain();
    gainNode.gain.setValueAtTime(1, this.audioContext.currentTime);

    sourceNode.connect(gainNode);
    gainNode.connect(this.audioContext.destination);

    sourceNode.onended = () => {
      this.isAudioPlaying = false;
      if (this.audioQueue.length > 0) {
        const nextAudioBuffer = this.audioQueue.shift();
        this.playAudio(nextAudioBuffer);
      }
    };

    sourceNode.start();
  }

  // Send Audio Data via WebSocket
  sendAudio = async (audioBlob) => {
    try {
      const arrayBuffer = await audioBlob.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      let binaryString = "";
      for (let i = 0; i < uint8Array.length; i++) {
        binaryString += String.fromCharCode(uint8Array[i]);
      }
      const base64Audio = btoa(binaryString);

      // Send the base64-encoded audio to the WebSocket server
      this.websocket.send(
        JSON.stringify({
          audio_data: base64Audio,
          response_type: this.state.responseType,
          voice: this.state.selectedVoice,
        })
      );

      // Optionally, append the audio to the chat
      const audioUrl = URL.createObjectURL(audioBlob);
      this.appendUserAudioMessage(audioUrl);
    } catch (error) {
      console.error("Error sending audio:", error);
    }
  };

  // Append User Audio Message to Chat Box
  appendUserAudioMessage(audioUrl) {
    const userMessage = document.createElement("div");
    userMessage.className = "user-audio-message mb-4 text-right";
    userMessage.innerHTML = `
      <audio controls>
        <source src="${audioUrl}" type="audio/wav">
        Your browser does not support the audio element.
      </audio>
    `;
    this.chatBox.current.appendChild(userMessage);
    this.chatBox.current.scrollTop = this.chatBox.current.scrollHeight;
  }

  render() {
    return (
      <div className="chat_container">
        <h2>Chat in Real Time</h2>
        <div className="controls">
          <label className="toggle-container">
            <input
              type="checkbox"
              ref={this.responseTypeToggle}
              className="toggle-input"
            />
            <span className="toggle-slider"></span>
            <span className="toggle-label">Audio</span>
          </label>

          <select ref={this.voiceSelector} className="voice-selector">
            <option value="alloy">Alloy</option>
            <option value="david">David</option>
            <option value="ash">Ash (M)</option>
            <option value="ballad">Ballad (M)</option>
            <option value="coral">Coral (F)</option>
            <option value="echo">Echo (M)</option>
            <option value="sage">Sage (F)</option>
            <option value="shimmer">Shimmer (F)</option>
            <option value="verse">Verse (M)</option>
          </select>
        </div>

        <div ref={this.chatBox} className="chat-box"></div>

        <div className="input-container">
          <textarea
            ref={this.textInput}
            className="text-input"
            placeholder="Type your message"
          ></textarea>
          <button ref={this.sendBtn} className="send-button">
            Send
          </button>
          <button ref={this.micBtn} className="mic-button">
            🎤
          </button>
        </div>
      </div>
    );
  }
}

export default Quickstart;
